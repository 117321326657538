import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "staying-up-to-date"
    }}>{`Staying up to date`}</h2>
    <h3 {...{
      "id": "problem"
    }}>{`Problem`}</h3>
    <p>{`You have a local service you need to keep synchronized with the Entur services.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p><img alt="Flow chart" src={require("./download.png")} /></p>
    <p>{`Synchronization from your service to Entur services is handled via the API. You can update directly on the customer or the given consent.`}</p>
    <p>{`Synchronization from Entur services to your systems happens via topic subscription.`}</p>
    <p>{`Entur exposes several different topics related to customers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Environment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Staging`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Production`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`customer-changed-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`customer-changed-production-$Org`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Given Consent changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`given-consent-changed-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`given-consent-changed-production-$Org`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`consent-changed-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`consent-changed-production-$Org`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Consent Base changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`consent-base-changed-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`consent-base-changed-production-$Org`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contract changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`contract-change-event-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`contract-change-event-production-$Org`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Loyalty Program changes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`loyaltyprogram-change-event-staging-$Org`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`loyaltyprogram-change-event-production-$Org`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`($Org is replaced with your assigned organisation Id, so the topic becomes for instance given-consent-changed-staging-35)`}</p>
    <p>{`The CustomerChange and GivenConsentChange objects are as small and GDPR friendly as we can make them.
Use the customerNumbers to look up in the API after receiving them.
The other change events give a complete image of the object after each change.`}</p>
    <p>{`The elements you fetch from these queues are slightly different:`}</p>
    <ExpandablePanel title="CustomerChange" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "CustomerChange",
  "namespace" : "org.entur.alfred.kafka.event",
  "doc" : "Event sent out by customers on any changes to a customer",
  "fields" : [ {
    "name" : "changeEvent",
    "type": {
        "type" : "enum",
        "symbols" : ["CREATE","UPDATE","DELETE"],
        "name": "EventType"
    },
    "doc" : "The type of event that happened"
  }, {
    "name" : "customerNumber",
    "type" : "string",
    "doc" : "The customer number"
  }, {
    "name" : "customerRef",
    "type": ["null", "string"],
    "default": null,
    "doc" : "The customer reference. This will usually, but not always, be the same as the customer number."
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "changedBy",
    "type": ["null", "string"],
    "default": null,
    "doc" : "Who claims to have made the change"
  }, {
    "name" : "processorOrganisation",
    "type" : [ "long", "null" ],
    "doc" : "The organisation allowed to process this event",
    "default" : 0
  },{
      "name" : "mergedIntoCustomerNumber",
      "type" : [ "null", "long" ],
      "doc" : "Set when this profile has been merged into a different profile. Update references to this profile to use new customer number.",
      "default" : null
  } ]
}
`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="GivenConsentChanged" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "GivenConsentChanged",
  "namespace" : "org.entur.consents.kafka.event",
  "doc" : "Event from GivenConsent.",
  "fields" : [ {
    "name" : "id",
    "type" : "long",
    "doc" : "The id of the given consent.",
    "default" : 0
  }, {
    "name" : "customerNumber",
    "type" : [ "string", "null" ],
    "doc" : "The customer ref describing which customer that is associated with this given consent."
  }, {
    "name" : "customerRef",
    "type" : [ "null", "string" ],
    "doc" : "The customer ref describing which customer that is associated with this given consent.",
    "default" : null
  }, {
    "name" : "consentId",
    "type" : "long",
    "doc" : "The id of the consent defining what and to which operator specific consent the customer has consented."
  }, {
    "name" : "consentCode",
    "type" : [ "null", "string" ],
    "doc" : "The consentCode of the consent defining what and to which operator specific consent the customer has consented.",
    "default" : null
  }, {
    "name" : "consentChoice",
    "type" : "boolean",
    "doc" : "Whether the customer consents or not."
  }, {
    "name" : "expirationDate",
    "type" : "string",
    "doc" : "The date when the given consent expired"
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "processorOrganisation",
    "type" : [ "long", "null" ],
    "doc" : "The organisation allowed to process this event",
    "default" : 0
  }, {
    "name" : "type",
    "type" : {
      "type" : "enum",
      "name" : "EventType",
      "namespace" : "org.entur.consents.kafka.GivenConsentChanged.type",
      "symbols" : [ "CREATE", "UPDATE", "DELETE" ]
    },
    "doc" : "The type of event that occurred"
  } ]
}
`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ConsentChanged" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "ConsentChanged",
  "namespace" : "org.entur.consents.kafka.event",
  "doc" : "Event from Consent.",
  "fields" : [ {
    "name" : "id",
    "type" : "long",
    "doc" : "The id of the changed event.",
    "default" : 0
  }, {
    "name" : "type",
    "type" : {
      "type" : "enum",
      "name" : "EventType",
      "symbols" : [ "CREATE", "UPDATE", "DELETE" ]
    },
    "doc" : "The type of event that happened"
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "processorOrganisation",
    "type" : "long",
    "doc" : "The organisation allowed to process this event"
  }, {
    "name" : "consent",
    "type" : {
      "type" : "record",
      "name" : "ConsentResponse",
      "doc" : "Which organisation that provides the content consented to",
      "fields" : [ {
        "name" : "changedAt",
        "type" : "string",
        "doc" : "When the consent was last changed. ISO 8601 date format"
      }, {
        "name" : "consentCode",
        "type" : "string",
        "doc" : "Consent code"
      }, {
        "name" : "createdAt",
        "type" : "string",
        "doc" : "When the consent was created. ISO 8601 date format"
      }, {
        "name" : "id",
        "type" : "long",
        "doc" : "Organisation consent ID."
      }, {
        "name" : "isEmailSupported",
        "type" : "boolean",
        "doc" : "If the current organisation supports email as a way of contact."
      }, {
        "name" : "isSmsSupported",
        "type" : "boolean",
        "doc" : "If the current organisation supports sms as a way of contact."
      }, {
        "name" : "organisationId",
        "type" : "long",
        "doc" : "The organisation the consent is valid for. Typically an 'Operatør'"
      }, {
        "name" : "validFrom",
        "type" : [ "null", "string" ],
        "doc" : "If set, the date the consent is valid from. Can only be set to a date after consent base validFrom. If not set, the consent base is in draft mode.",
        "default" : null
      }, {
        "name" : "validTo",
        "type" : [ "null", "string" ],
        "doc" : "If set, the date the consent is valid to. Set the date to deactivate the consent. Can only be set to a date before the consent base validTo. If not set the consent is active from validFrom and forever after.",
        "default" : null
      } ]
    },
    "doc" : "The consent after the change"
  } ]
}
`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ConsentBaseChanged" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "ConsentBaseChanged",
  "namespace" : "org.entur.consents.kafka.event",
  "doc" : "Event from ConsentBase.",
  "fields" : [ {
    "name" : "id",
    "type" : "long",
    "doc" : "The id of the changed event.",
    "default" : 0
  }, {
    "name" : "type",
    "type" : {
      "type" : "enum",
      "name" : "EventType",
      "symbols" : [ "CREATE", "UPDATE", "DELETE" ]
    },
    "doc" : "The type of event that happened"
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "processorOrganisation",
    "type" : "long",
    "doc" : "The organisation allowed to process this event"
  }, {
    "name" : "consentBase",
    "type" : {
      "type" : "record",
      "name" : "ConsentBaseResponse",
      "doc" : "Representing the body for a PUT or POST response for consent base",
      "fields" : [ {
        "name" : "changedAt",
        "type" : "string",
        "doc" : "When the consent was last changed. ISO 8601 date format"
      }, {
        "name" : "consentCode",
        "type" : "string",
        "doc" : "Consent code, identifying the consent base with version"
      }, {
        "name" : "consentOwnerOrgId",
        "type" : [ "null", "long" ],
        "doc" : "If set, the organisation which owns the consent, and can use it exclusively",
        "default" : null
      }, {
        "name" : "createdAt",
        "type" : "string",
        "doc" : "When the consent was created. ISO 8601 date format"
      }, {
        "name" : "internalDescription",
        "type" : [ "null", {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "InternalDescriptionResponse",
            "doc" : "Contains description for a consent. Will not be shown to customer",
            "fields" : [ {
              "name" : "description",
              "type" : "string",
              "doc" : "A description for the partner administrating consents"
            }, {
              "name" : "languageCode",
              "type" : "string",
              "doc" : "ISO-639-3 language code. 3 characters"
            } ]
          }
        } ],
        "doc" : "Internal description, describing the use cases for the consent",
        "default" : null
      }, {
        "name" : "orgNameSubstitutionPattern",
        "type" : [ "null", "string" ],
        "doc" : "The pattern on which to perform substitution within consentDescription and consentTerms. Facilitates reusable consents across organisations",
        "default" : null
      }, {
        "name" : "terms",
        "type" : {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "ConsentTermsResponse",
            "doc" : "Contains text for terms in a specific language. Clients may only use short description to obtain consents and opt-in full description if necessary",
            "fields" : [ {
              "name" : "fullDescription",
              "type" : "string",
              "doc" : "Text describing the terms in detail. The text can contain MarkDown"
            }, {
              "name" : "languageCode",
              "type" : "string",
              "doc" : "ISO-639-3 language code. 3 characters"
            }, {
              "name" : "shortDescription",
              "type" : "string",
              "doc" : "Short text explaining the terms. The text can contain MarkDown"
            } ]
          }
        },
        "doc" : "Consent description and consent in multiple languages. The terms can contain MarkDown"
      }, {
        "name" : "validFrom",
        "type" : [ "null", "string" ],
        "doc" : "If set, the date the consent is valid from. It is not possible to change the consent terms after this date without creating a new version. If not set, the consent is in draft mode.",
        "default" : null
      }, {
        "name" : "validTo",
        "type" : [ "null", "string" ],
        "doc" : "If set, the date the consent is valid to. Set the date to deactivate the consent. If not set, the consent is active from validFrom and forever after",
        "default" : null
      }, {
        "name" : "version",
        "type" : "int",
        "doc" : "The consent version, identifying consent base with consent code"
      } ]
    },
    "doc" : "The consent base after the change"
  } ]
}
`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ContractChanged" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "ContractChange",
  "namespace" : "org.entur.loyaltyprogram.kafka.event",
  "doc" : "Event sent out by loyaltyprogram on any changes to a Contract.",
  "fields" : [ {
    "name" : "changeEvent",
    "type" : {
      "type" : "enum",
      "name" : "EventType",
      "symbols" : [ "CREATE", "UPDATE", "DELETE" ]
    },
    "doc" : "The type of event that happened"
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "processorOrganisation",
    "type" : "long",
    "doc" : "The organisation allowed to process this event"
  }, {
    "name" : "id",
    "type" : "string",
    "doc" : "The object id"
  }, {
    "name" : "contract",
    "type" : {
      "type" : "record",
      "name" : "ContractResponse",
      "namespace" : "org.entur.loyaltyprogram.kafka.event.domain",
      "doc" : "A contract between a customer and an organisation",
      "fields" : [ {
        "name" : "uuid",
        "type" : "string",
        "doc" : "Unique contract identifier"
      }, {
        "name" : "externalRef",
        "type" : [ "null", "string" ],
        "doc" : "Optional external reference. Examples are membership number and employee number",
        "default" : null
      }, {
        "name" : "organisationId",
        "type" : "long",
        "doc" : "Which organisation the contract concerns"
      }, {
        "name" : "acceptanceDate",
        "type" : [ "null", "string" ],
        "doc" : "When the contract was accepted by the customer",
        "default" : null
      }, {
        "name" : "consumableFrom",
        "type" : "string",
        "doc" : "From when the contract can be consumed"
      }, {
        "name" : "expirationDate",
        "type" : [ "null", "string" ],
        "doc" : "When the contract expires",
        "default" : null
      }, {
        "name" : "loyaltyProgram",
        "type" : {
          "type" : "record",
          "name" : "LoyaltyProgramFlatResponse",
          "doc" : "The current values for a loyalty program",
          "fields" : [ {
            "name" : "id",
            "type" : "long",
            "doc" : "The id of the loyalty program"
          }, {
            "name" : "organisationId",
            "type" : "long",
            "doc" : "The organisation that owns this loyalty program."
          }, {
            "name" : "internalDescription",
            "type" : "string",
            "doc" : "A human readable internal description of the loyalty program."
          }, {
            "name" : "productId",
            "type" : "string",
            "doc" : "The id of the product associated with this loyalty program."
          }, {
            "name" : "productVersion",
            "type" : "string",
            "doc" : "The version of the product associated with this loyalty program."
          }, {
            "name" : "startDate",
            "type" : "string",
            "doc" : "The start date of the loyaltyprogram. If specified ahead in time, the loyaltyprogram will get status = draft. Supports ISO 8601 date format."
          }, {
            "name" : "endDate",
            "type" : "string",
            "doc" : "The end date of the loyaltyprogram. All contracts will expire when the loyaltyprogram expires. Supports ISO 8601 date format."
          }, {
            "name" : "usageValidityPeriod",
            "type" : [ "null", "string" ],
            "doc" : "The duration of a contract associated with this loyaltyprogram. The default value is null, which means the contract has an unlimited duration. This field uses the ISO 8601 duration format and accept the units: Days and Time. For example; 'P3DT12H30M5S' represents a duration of three days, twelve hours, thirty minutes, and five seconds.",
            "default" : null
          }, {
            "name" : "defaultCouponsLimit",
            "type" : [ "null", "long" ],
            "doc" : "How many coupons (eg. the maximum amount of usages) the underlying contracts of the loyalty program has.",
            "default" : null
          }, {
            "name" : "versionNumber",
            "type" : "long",
            "doc" : "The version of this loyalty program."
          }, {
            "name" : "status",
            "type" : {
              "type" : "enum",
              "name" : "LoyaltyProgramStatus",
              "doc" : "LoyaltyProgramStatus",
              "symbols" : [ "DRAFT", "CURRENT", "DEPRECATED" ]
            },
            "doc" : "The current status of this loyalty program. Can be DRAFT, CURRENT, DEPRECATED"
          }, {
            "name" : "descriptions",
            "type" : {
              "type" : "array",
              "items" : {
                "type" : "record",
                "name" : "LoyaltyProgramDescriptionResponse",
                "doc" : "Description of a loyalty program version",
                "fields" : [ {
                  "name" : "languageCode",
                  "type" : "string",
                  "doc" : "What language the description is written in, ISO639-3"
                }, {
                  "name" : "description",
                  "type" : "string",
                  "doc" : "Loyalty program description. Supports any kind of text"
                }, {
                  "name" : "createdAt",
                  "type" : "string",
                  "doc" : "When the loyalty program was created"
                }, {
                  "name" : "createdBy",
                  "type" : "string",
                  "doc" : "Which client created the loyalty program"
                }, {
                  "name" : "lastChangedAt",
                  "type" : "string",
                  "doc" : "When the loyalty program was last changed"
                }, {
                  "name" : "lastChangedBy",
                  "type" : "string",
                  "doc" : "Which client changed the loyalty program last"
                } ]
              }
            },
            "doc" : "A list of human readable descriptions in different languages. Only one language per description is allowed."
          } ]
        },
        "doc" : "The loyalty program included in this contract"
      }, {
        "name" : "createdAt",
        "type" : "string",
        "doc" : "When the contract was created"
      }, {
        "name" : "createdBy",
        "type" : "string",
        "doc" : "Which client created the contract"
      }, {
        "name" : "lastChangedAt",
        "type" : "string",
        "doc" : "When the contract was last changed"
      }, {
        "name" : "couponsLimit",
        "type" : [ "null", "long" ],
        "doc" : "How many coupons the contract has. Default is cascaded from Loyalty Program Version. If set, the contract will be blocked for usage when all coupons are used. Coupons are registered via an OrderLineEvent.",
        "default" : null
      }, {
        "name" : "lastChangedBy",
        "type" : "string",
        "doc" : "Which client changed the contract last"
      }, {
        "name" : "orderLineEvents",
        "type" : [ "null", {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "OrderLineEventResponse",
            "doc" : "An order line event on a contract",
            "fields" : [ {
              "name" : "orderId",
              "type" : "string",
              "doc" : "The order ID"
            }, {
              "name" : "orderLineId",
              "type" : "string",
              "doc" : "The order line ID"
            }, {
              "name" : "orderLineVersion",
              "type" : "string",
              "doc" : "The order line version"
            }, {
              "name" : "price",
              "type" : "string",
              "doc" : "The order line price"
            }, {
              "name" : "couponsUsed",
              "type" : [ "null", "long" ],
              "doc" : "How many coupons (usages) this event is using.",
              "default" : null
            }, {
              "name" : "organisationId",
              "type" : "long",
              "doc" : "The organisation through which the order was placed"
            }, {
              "name" : "timestamp",
              "type" : "string",
              "doc" : "When the order line event was saved"
            }, {
              "name" : "isPurchased",
              "type" : "boolean",
              "doc" : "Whether the order line event describes the purchase of a contract, or contract consumer"
            }, {
              "name" : "isCancelled",
              "type" : "boolean",
              "doc" : "Whether the order line event is a cancellation"
            }, {
              "name" : "travelDate",
              "type" : [ "null", "string" ],
              "doc" : "The date of travel, related to entitlement usage",
              "default" : null
            } ]
          }
        } ],
        "doc" : "A list of order line events related to this contract",
        "default" : null
      }, {
        "name" : "policies",
        "type" : [ "null", {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "PolicyResponse",
            "doc" : "Policy, returns keys that have to match",
            "fields" : [ {
              "name" : "id",
              "type" : "long",
              "doc" : "Id of the policy"
            }, {
              "name" : "key",
              "type" : "string",
              "doc" : "Key for which field that should be matching"
            } ]
          }
        } ],
        "doc" : "The list of keys validated against the customer claiming the contract",
        "default" : null
      }, {
        "name" : "contractConsumers",
        "type" : [ "null", {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "ContractConsumerResponse",
            "doc" : "A description of the customer that's allowed to use the contract",
            "fields" : [ {
              "name" : "customerOrganisationId",
              "type" : "long",
              "doc" : "The organisation ID the customer belongs to"
            }, {
              "name" : "customerNumber",
              "type" : [ "null", "long" ],
              "doc" : "The customer entitled to use the contract. Unique Entur specific number",
              "default" : null
            }, {
              "name" : "customerRef",
              "type" : [ "null", "string" ],
              "doc" : "The customer entitled to use the contract. External reference unique to organisation",
              "default" : null
            }, {
              "name" : "isBlocked",
              "type" : "boolean",
              "doc" : "Marks that this specific user has been disallowed from using this contract by the contract owner"
            }, {
              "name" : "isContractHolder",
              "type" : "boolean",
              "doc" : "Marks that this specific user's organisation is the owner of the contract"
            }, {
              "name" : "createdAt",
              "type" : "string",
              "doc" : "When the contract consumer was created"
            }, {
              "name" : "createdBy",
              "type" : "string",
              "doc" : "Which client created the contract consumer"
            }, {
              "name" : "lastChangedAt",
              "type" : "string",
              "doc" : "When the contract consumer was last changed"
            }, {
              "name" : "lastChangedBy",
              "type" : "string",
              "doc" : "Which client changed the contract consumer last"
            } ]
          }
        } ],
        "doc" : "The list of customers allowed to use the contract",
        "default" : null
      }, {
        "name" : "transactions",
        "type" : [ "null", {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "TransactionResponse",
            "fields" : [ {
              "name" : "timestamp",
              "type" : "string",
              "doc" : "Timestamp for this transaction."
            }, {
              "name" : "amount",
              "type" : "string",
              "doc" : "How much was the amount changed by this transaction? Positive value means deposit onto the card, negative withdrawal."
            }, {
              "name" : "currency",
              "type" : "string",
              "doc" : "Currency used in this transaction. Only one currency is allowed for all transactions on a single contract."
            }, {
              "name" : "externalTransactionId",
              "type" : "string",
              "doc" : "External transaction id for the ongoing transaction."
            }, {
              "name" : "rrn",
              "type" : "string",
              "doc" : "External RRN for the payment."
            }, {
              "name" : "internalTransactionId",
              "type" : "string",
              "doc" : "Internal unique identifier for this transaction."
            } ]
          }
        } ],
        "doc" : "The list of transactions for this contract (earn/burn or giftcard)",
        "default" : null
      }, {
        "name" : "currentTotal",
        "type" : [ "null", {
          "type" : "record",
          "name" : "TotalAmount",
          "fields" : [ {
            "name" : "currency",
            "type" : "string",
            "doc" : "Currency of the amount"
          }, {
            "name" : "amount",
            "type" : "string",
            "doc" : "Amount amount. Format: -?\\d*\\.\\d\\d  Examples: 123.34  1.00  34000.00"
          }, {
            "name" : "calculatedAt",
            "type" : "string",
            "doc" : "When was the amount calculated"
          } ]
        } ],
        "doc" : "The current total for this contract (earn/burn or giftcard)",
        "default" : null
      }, {
        "name" : "remainingCoupons",
        "type" : [ "null", "long" ],
        "doc" : "The remaining coupons for coupon based contracts. Derived from couponsLimit and orderLineEvents",
        "default" : null
      } ]
    },
    "doc" : "The contract after the change"
  } ]
}
`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="LoyaltyprogramChanged" mdxType="ExpandablePanel">
      <pre>{`
{
  "type" : "record",
  "name" : "LoyaltyProgramChange",
  "namespace" : "org.entur.loyaltyprogram.kafka.event",
  "doc" : "Event sent out by loyaltyprogram on any changes to a Loyalty program.",
  "fields" : [ {
    "name" : "changeEvent",
    "type" : {
      "type" : "enum",
      "name" : "EventType",
      "symbols" : [ "CREATE", "UPDATE", "DELETE" ]
    },
    "doc" : "The type of event that happened"
  }, {
    "name" : "timestamp",
    "type" : "string",
    "doc" : "When the event occurred"
  }, {
    "name" : "processorOrganisation",
    "type" : "long",
    "doc" : "The organisation allowed to process this event"
  }, {
    "name" : "id",
    "type" : "string",
    "doc" : "The object id"
  }, {
    "name" : "loyaltyProgram",
    "type" : {
      "type" : "record",
      "name" : "LoyaltyProgramFlatResponse",
      "namespace" : "org.entur.loyaltyprogram.kafka.event.domain",
      "doc" : "The current values for a loyalty program",
      "fields" : [ {
        "name" : "id",
        "type" : "long",
        "doc" : "The id of the loyalty program"
      }, {
        "name" : "organisationId",
        "type" : "long",
        "doc" : "The organisation that owns this loyalty program."
      }, {
        "name" : "internalDescription",
        "type" : "string",
        "doc" : "A human readable internal description of the loyalty program."
      }, {
        "name" : "productId",
        "type" : "string",
        "doc" : "The id of the product associated with this loyalty program."
      }, {
        "name" : "productVersion",
        "type" : "string",
        "doc" : "The version of the product associated with this loyalty program."
      }, {
        "name" : "startDate",
        "type" : "string",
        "doc" : "The start date of the loyaltyprogram. If specified ahead in time, the loyaltyprogram will get status = draft. Supports ISO 8601 date format."
      }, {
        "name" : "endDate",
        "type" : "string",
        "doc" : "The end date of the loyaltyprogram. All contracts will expire when the loyaltyprogram expires. Supports ISO 8601 date format."
      }, {
        "name" : "usageValidityPeriod",
        "type" : [ "null", "string" ],
        "doc" : "The duration of a contract associated with this loyaltyprogram. The default value is null, which means the contract has an unlimited duration. This field uses the ISO 8601 duration format and accept the units: Days and Time. For example; 'P3DT12H30M5S' represents a duration of three days, twelve hours, thirty minutes, and five seconds.",
        "default" : null
      }, {
        "name" : "defaultCouponsLimit",
        "type" : [ "null", "long" ],
        "doc" : "How many coupons (eg. the maximum amount of usages) the underlying contracts of the loyalty program has.",
        "default" : null
      }, {
        "name" : "versionNumber",
        "type" : "long",
        "doc" : "The version of this loyalty program."
      }, {
        "name" : "status",
        "type" : {
          "type" : "enum",
          "name" : "LoyaltyProgramStatus",
          "doc" : "LoyaltyProgramStatus",
          "symbols" : [ "DRAFT", "CURRENT", "DEPRECATED" ]
        },
        "doc" : "The current status of this loyalty program. Can be DRAFT, CURRENT, DEPRECATED"
      }, {
        "name" : "descriptions",
        "type" : {
          "type" : "array",
          "items" : {
            "type" : "record",
            "name" : "LoyaltyProgramDescriptionResponse",
            "doc" : "Description of a loyalty program version",
            "fields" : [ {
              "name" : "languageCode",
              "type" : "string",
              "doc" : "What language the description is written in, ISO639-3"
            }, {
              "name" : "description",
              "type" : "string",
              "doc" : "Loyalty program description. Supports any kind of text"
            }, {
              "name" : "createdAt",
              "type" : "string",
              "doc" : "When the loyalty program was created"
            }, {
              "name" : "createdBy",
              "type" : "string",
              "doc" : "Which client created the loyalty program"
            }, {
              "name" : "lastChangedAt",
              "type" : "string",
              "doc" : "When the loyalty program was last changed"
            }, {
              "name" : "lastChangedBy",
              "type" : "string",
              "doc" : "Which client changed the loyalty program last"
            } ]
          }
        },
        "doc" : "A list of human readable descriptions in different languages. Only one language per description is allowed."
      } ]
    },
    "doc" : "The loyalty program after the change"
  } ]
}
`}</pre>
    </ExpandablePanel>
    <p>{`An example client for consuming these events:`}</p>
    <pre><code parentName="pre" {...{}}>{`val properties = Properties()
properties[ProducerConfig.BOOTSTRAP_SERVERS_CONFIG] = "https://kafka-0.entur.io:9095,https://kafka-1.entur.io:9095,https://kafka-2.entur.io:9095"
properties[ConsumerConfig.GROUP_ID_CONFIG] = "teamculp-test-1"
properties[ConsumerConfig.ENABLE_AUTO_COMMIT_CONFIG] = false
properties[ConsumerConfig.AUTO_OFFSET_RESET_CONFIG] = "earliest"
properties[ConsumerConfig.KEY_DESERIALIZER_CLASS_CONFIG] = StringDeserializer::class.java
properties[ConsumerConfig.VALUE_DESERIALIZER_CLASS_CONFIG] = KafkaAvroDeserializer::class.java
properties["specific.avro.reader"] = true
properties["schema.registry.url"] = "http://schema-registry.entur.io:8001"

properties[CommonClientConfigs.SECURITY_PROTOCOL_CONFIG] = "SASL_SSL"

properties[SaslConfigs.SASL_MECHANISM] = "SCRAM-SHA-512"
properties[SaslConfigs.SASL_JAAS_CONFIG] = String.format("org.apache.kafka.common.security.scram.ScramLoginModule required\\nusername=\\"%s\\"\\npassword=\\"%s\\";", "username", "password")

val consumer: Consumer<String, CustomerChange> = KafkaConsumer(properties)

consumer.subscribe(asList("customer-changed-staging-...."))
val records = consumer.poll(Duration.of(10, ChronoUnit.SECONDS))

log.info("records fetched: {}", records.count())

if (!records.isEmpty)
    for (record in records.records("customer-changed-staging-....")) {
        log.info("record={}", record)
    }
`}</code></pre>
    <p>{`Note:`}</p>
    <ul>
      <li parentName="ul">{`for the customer services, we are using StringDeserializer for the keys.`}</li>
      <li parentName="ul">{`Entur uses LetsEncrypt to sign our certificates. You should use the truststore that is bundeled with your JDK/JRE`}</li>
      <li parentName="ul">{`You will receive the correct urls, username and password from Entur.`}</li>
      <li parentName="ul">{`GROUP_ID_CONFIG must be the same on all clients in a cluster. It should be set explicitly. Also, use another group Id when testing.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      